<template>
    <div style="display: flex">
        <v-data-table
            id="virtual-scroll-table-2"
            v-if="costs"
            :loading="loading"
            :headers="headers"
            disable-pagination
            hide-default-footer
            class="ma-0 pa-0"
            style="width: auto !important;"
            :items="costs"
        >
            <template v-slot:[`item.row`]="{ index }">
                {{ index + 1 }}
            </template>
            <template v-slot:[`item.salePrice2`]="{ item }">
                {{
                    new Intl.NumberFormat('de-DE', {
                        style: 'currency',
                        currency: quote.currency,
                    }).format(item.salePrice2)
                }}
            </template>
            <template v-slot:[`item.pricePerQuantity2`]="{ item }">
                {{
                    new Intl.NumberFormat('de-DE', {
                        style: 'currency',
                        currency: quote.currency,
                    }).format(item.salePrice / quote.exchange / item.quantity)
                }}
                / {{ item.measure }}
            </template>

            <template v-slot:footer>
                <v-simple-table dense class="my-4">
                    <tfoot class="grey lighten-3">
                        <tr>
                            <td class="font-weight-bold">TOTAL</td>
                            <td class="font-weight-bold text-right">
                                {{
                                    new Intl.NumberFormat('de-DE', {
                                        style: 'currency',
                                        currency: quote.currency,
                                    }).format(getTotalCosts())
                                }}
                            </td>
                        </tr>
                    </tfoot>
                </v-simple-table>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import API from '@/services/api'
import { mapState } from 'vuex'

export default {
    name: 'Costs',
    props: {
        quoteId: String,
        quote: Object,
    },
    data: () => ({
        loading: false,
        errorMsg: '',
        costs: [],
        headers: [
            {
                text: 'Row',
                value: 'row',
                class: 'grey lighten-3',
                width: 50,
            },
            {
                text: 'COST',
                value: 'reference',
                class: 'grey lighten-3',
                width: 300,
            },
            {
                text: 'QTY',
                value: 'quantity',
                class: 'grey lighten-3',
            },
            {
                text: 'PRICE PER QUANTITY',
                value: 'pricePerQuantity2',
                class: 'grey lighten-3',
            },
            {
                text: 'SALE PRICE',
                value: 'salePrice2',
                class: 'grey lighten-3',
            },
        ],
    }),
    watch: {
        async quoteId() {
            await this.getCosts()
        },
    },
    async mounted() {
        await this.getCosts()
    },
    computed: {
        ...mapState(['settings']),
    },
    methods: {
        getTotalCosts() {
            if (this.costs.length > 0) {
                return this.costs.reduce(
                    (acumulator, cost) => acumulator + cost.salePrice2,
                    0
                )
            }
            return 0
        },
        async getCosts() {
            try {
                this.loading = true
                this.errorMsg = null
                let costs = await API.getCosts(this.quoteId)
                costs = costs.filter(cost => cost.type)
                this.costs = costs.filter(cost => cost.type == 'cost')
            } catch (error) {
                this.errorMsg = error.message
            } finally {
                this.loading = false
            }
        },
    },
}
</script>

<style scoped>
#virtual-scroll-table-2 >>> tbody {
    font-weight: normal;
}
</style>
